const slider = tns({
    container: '.js-testimonials',
    items: 1,
    nav: false,
    autoplay: true,
    autoplayTimeout: 15000,
    mouseDrag: true,
    swipeAngle: false,
    autoplayHoverPause: true,
    loop: true,
    controls: true,
    controlsText: [
        "<img class='icon--nav' src='//firstfloridaprobate.com/images/arrow-left.webp' loading='lazy' width='28' height='22' alt='arrow left' />",
        "<img class='icon--nav' src='//firstfloridaprobate.com/images/arrow-right.webp' loading='lazy' width='28' height='22' alt='arrow right' />"
    ]
});

const buttons = document.querySelectorAll('.js-scrollTo');
buttons.forEach((button) => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        const target = button.getAttribute('href');
        const targetEl = document.querySelector(target);
        targetEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    });
  });